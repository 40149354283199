window.withPopoup = () => {
  document.body.classList.add('with_popup');
  document.querySelector('.wrappy').addEventListener('click', window.removePopoup);
}
window.removePopoup = () => {
  document.body.classList.remove('with_popup')
  document.querySelector('.wrappy').removeEventListener('click', window.removePopoup);
};
window.randomlyShowPopup = () => {  
  if (Math.random() < 0.10) {
    setTimeout(window.withPopoup, 1000);
  }
}
