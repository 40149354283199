import debounce from 'debounce'
import { isVisible } from './is_visible.js'

let startTime;
let writerProfile;
let sendReadDone;
let sendRead = function(status) {
  fetch(`/reads`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({...{ read: window.readPms}, ...{status: status}})
  });
}
const allowAction = ['papers#show', 'blog_posts#show']; //, 'challenges#show']
let radingy = function() {
  if (sendReadDone) return console.log('dondon');
  
  if (!allowAction.includes(window.rac)) return;
  // let offset = d.scrollTop + window.innerHeight;

  let mri_ = window.mri * 0.2; // allow speed reading
  let time_since_page_load = (Date.now() - startTime) / 60000;
  if (time_since_page_load < mri_) return;
  if (!isVisible(writerProfile)) return;
  sendRead('done');
  sendReadDone = true;
};

let debouncedRadingy = debounce(radingy, 200);

function scrollFunction() {
  debouncedRadingy();
  // if (!bottomNav) return;
  // if (document.body.scrollTop > 130 || document.documentElement.scrollTop > 130) {
  //   bottomNav.className = 'main_nav topi';
  // } else {
  //   bottomNav.className = 'main_nav';
  // }
}
const setupReads = () => { 
  sendReadDone = false;
  writerProfile = document.querySelector('.writer_profile')
  startTime = Date.now()
  sendRead();
  window.onscroll = debounce(scrollFunction, 30)
}
export { setupReads };
