/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import 'core-js/stable'
// import 'regenerator-runtime/runtime'
const Rails = require("@rails/ujs")
Rails.start();
require("turbolinks").start()
// require("@rails/activestorage").start()
// import Pusher from 'pusher-js';
// import { setupDiscussionSeen } from './ununseen.js';
// import * as ActiveStorage from "@rails/activestorage"
// ActiveStorage.start()
import { setupReads } from '../lib/reads.js';
// import { setupEditor } from './editor.js';
import '../lib/esc.js';
import '../lib/popup.js';
// import debounce from 'debounce'
import { setupDiscussion } from '../lib/discussion.js'

import '../lib/setup.js'
const setup = (event) => {
  // setupDiscussion();
  // setupDiscussionSeen();
  setupReads();
  // setupEditor();
}
document.addEventListener("turbolinks:load", setup); 
