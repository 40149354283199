// Turbolinks.scroll = {};

// Obviously doesn't need to be on window
window.scrollPosition = {x: 0, y: 0} // Our 'old' scroll position
window.lastHref = null // Our 'old' page href

const koko = (e) => {
  if (window.didntSaveYet) {
    e.preventDefault();
    e.returnValue = '';
  } else {
    delete e['returnValue'];
  }
}

document.addEventListener('turbolinks:unload', koko);
const dispatchUnloadEvent = function() {
  let event = document.createEvent("Events")
  event.initEvent("turbolinks:unload", true, false)
  document.dispatchEvent(event)
}

addEventListener("beforeunload", koko);
addEventListener("turbolinks:before-render", dispatchUnloadEvent)
//i Before visit, simply store scroll position & url/href
document.addEventListener('turbolinks:before-visit', function () {
  window.scrollPosition = {x: window.scrollX, y: window.scrollY}
  window.lastHref = window.location.href
}, false)

// After load
document.addEventListener('turbolinks:load', function () {
  // If we have a scroll position AND we're on the same page
  if (window.scrollPosition && (this.location.href == window.lastHref)) {
    // Scroll to our previous position
    window.scrollTo(window.scrollPosition.x, window.scrollPosition.y)
  }
}, false)

window.firstLogin = () => {
  document.body.classList.add('with_popup');
}
// document.addEventListener("DOMContentLoaded", setup); 


