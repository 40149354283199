const Rails = require("@rails/ujs")
import ClipboardJS from 'clipboard';

let channel;
let userChannel;
let subscribedDiscussionRoom;
const racs = ['group_chats#show','chats#show', 'posts#show', 'papers#show', 'challenges#show'];
const pracs = ['posts#show', 'papers#show', 'challenges#show']
function pusherNotificationsBoardSetup() {
  if (!window.pusherClient) return false;
  let st;
  const nb = document.querySelector('.notifications_board');
  const uc = document.getElementById('unseen_count');
  if (!userChannel) {
    userChannel = window.pusherClient.subscribe('user-' + window.currentUserId);
    // console.log('userChannel', userChannel);
    userChannel.bind('unseenCount', function(m) {
      uc.innerHTML = m.count;
      uc.style.display = 'block';
      // if (nb.innerHTML !== '') uc.click();
    //   if (document.querySelector(`[data-message-id="${m.id}"]`)) return false;
    //   console.log('fetching');
    //   fetch(`/users/${m.id}/notifications`).then(function (response) {
    //     // The API call was successful!
    //     return response.text();
    //   }).then(function (html) {
    //     nb.innerHTML = html;
    //     nb.style.display = 'flex';
    //   }).catch(function (err) {
    //     // There was an error
    //     console.warn('Something went wrong.', err);
    //   });
    });
  }

}
const scrollDownDiscussion = function() {
  const dmo = document.querySelector('.discussion_messages');
  if (dmo) dmo.scrollTop = dmo.scrollHeight;
}
window.scrollDownDiscussion = scrollDownDiscussion;

function pusherDiscussionsSetup() {
  if (!window.pusherClient) return false;
  let st;
  if (!racs.includes(window.rac)) return;
  if (channel && subscribedDiscussionRoom) st = true;
  if (st) channel.unsubscribe(subscribedDiscussionRoom);
  subscribedDiscussionRoom = window.discussionId+'';
  channel = window.pusherClient.subscribe(subscribedDiscussionRoom);
  // console.log('listen11');
  if (!st) {
    channel.bind('newMsg', function(m) {
      console.log('newMsg', m);
      if (document.querySelector(`[data-message-id="${m.id}"]`)) return false;
      console.log('fetching');
      fetch(location.href).then(function (response) {
        // The API call was successful!
        return response.text();
      }).then(function (html) {

        // Convert the HTML string into a document object
        
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const x = (pracs.includes(window.rac)) ? '.post_comments' : '.discussion_messages';
        const dm = doc.querySelector(x);
        const dmo = document.querySelector(x);
        dmo.innerHTML = dm.innerHTML;
        scrollDownDiscussion();
      }).catch(function (err) {
        // There was an error
        console.warn('Something went wrong.', err);
      });
    });
  }
}


const setupCommentForm = () => {
  const cf = document.querySelector('#message_content');
  cf.focus();
  cf.addEventListener('keyup', (e) => {
    if (e.keyCode == 13) {
      //      if (e.shiftKey === true)
      if (e.shiftKey)  // thruthy
      {
        // new line
      }
      else
      {
        e.stopPropagation(); 
        Rails.fire(e.target.form, 'submit');
        // run your function
      }
      return false;
    }
  });
}
const setupPusher = () => {
  if (window.currentUserId && !window.pusherClient) {
    window.pusherClient = new Pusher(window.pusherKey, {
      cluster: 'eu',
      forceTLS: true
    });
  }
}

const setupDiscussion = () => {
  new ClipboardJS('.link_to_add_users');
  new ClipboardJS('.link_to_add_users_btn');
  const dmo = document.querySelector('.discussion_messages');
  if (dmo) dmo.scrollTop = dmo.scrollHeight;
  if (dmo) setupCommentForm();
  setupPusher();
  pusherDiscussionsSetup();
  pusherNotificationsBoardSetup();

  // document.querySelector('.group-chat-token').addEventListener('click', (e) => { copy(e.value) });
}
export { setupDiscussion }
